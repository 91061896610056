// data rendered `/src/pages/admin.js`
export const adminMenuData = [
  {
    label: 'Lab Metting', 
    subList: [
      {label: 'LabMeeting Schedule', value: 'lab-meeting-schedule'}
    ]
  },
  {
    label: 'Mass Spectrometry (MS)',
    subList: [
      {label: 'MS Status', value: 'ms-status'},
      {label: 'MS Booking', value: 'ms-booking'},
      {label: 'MS Request Form', value: 'ms-request-form'}
    ]
  },
  {
    label: 'File Management',
    subList: [
      {label: 'Manage Files', value: 'manage-files'},
      {label: 'Manage File Categories', value: 'manage-file-categories'},
      {label: 'Manage File Tags', value: 'manage-file-tags'},
      {label: 'Create New Files', value: 'create-new-files'},
    ]
  }
];

// ***
// used in `src/components/menu.main.js` to construct <Menu />
// data also used `/src/pages/index.js` to enable dymanic rendering
// data format ***
// objects inside arrays
// every nested object has the following data structure:
// {label: "", value: "", subList: []}
export const mainMenuData = [
  {
    label: 'Lab Metting', 
    value: 'lab-meeting',
    subList: [
      {
        label: 'LabMeeting Schedule', 
        value: 'lab-meeting-schedule',
        subList: null
      }
    ]
  },
  {
    label: 'Mass Spectrometry (MS)',
    subList: [
      {
        label: 'MS Status', 
        value: 'ms-status',
        subList: null
      },
      {
        label: 'MS Booking', 
        value: 'ms-booking',
        subList: null
      },
    ]
  },
  {
    label: 'Lab Files',
    subList: [
      {
        label: 'File List1', 
        value: 'file-list1',
        subList: null,
      },
      {
        label: 'File List2', 
        value: 'file-list2',
        subList: [
          {
            label: 'foo1',
            value: 'foo1',
            subList: null
          },
          {
            label: 'foo2',
            value: 'foo2',
            subList: null
          }
        ],
      },
    ]
  },
  {
    label: 'Account',
    subList: [
      {
        label: 'Settings', 
        value: 'account-settings',
        subList: null
      }
    ]
  }
];