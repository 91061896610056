import React from 'react';

/**
 * 
 * @param {object} props
 * @param {string} props.label
 * @param {object} props.value - {value: "", id: ""} reported category value
 * @param {array} props.buttons - ['edit', 'update', 'delete'] are the values determine which buttons to display
 * @param {object} props.updatedValue - {value: "", id: ""} being updated value
 * @param {string} props.editable - determins whether the input ele is editable
 * @param {function} props.updateValue - update based on onChange event
 * @param {function} props.saveChanges - save the updated values
 * @param {function} props.delete - delete a value
 * @param {function} props.enableEditing - report current component value back to parent
 */

const EditableInputElement = (props) => {
  return (
    <div>
      <label className="label">{props.label}</label>
      <div className="field is-grouped">
        <div className="control is-expanded">
          {
            props.editable
            &&
            <input className="input" 
              type="text" 
              value={props.updatedValue.value}
              onChange={(e)=>{props.updateValue(e.target.value)}} />
          }
          {
            !props.editable
            &&
            <input className="input" 
              disabled
              type="text" 
              value={props.value.value} />
          }
        </div>
        
      
        {
          props.buttons.map((button, index)=>{
            return(<div key={index} className="control">
              {
                button === 'edit'
                &&
          
                <button className="button is-primary"
                  onClick={()=>props.enableEditing(props.value)}
                >Enable Editing</button>
              }

              {
                button === 'update'
                &&
            
                <button className="button is-link"
                  onClick={props.saveChanges}
                >Save Changes</button>
              }

              {
                button === 'delete'
                &&
                <button className="button is-warning"
                  onClick={()=>props.delete(props.value)}
                >Delete</button>
              }
            </div>)
            })
          }
      </div>
    </div>
  )
}

export default EditableInputElement;

EditableInputElement.defaultProps = {
  label: "",
  value: {},
  buttons: [],
  updatedValue: {},
  editable: false,
  updateValue: ()=>"",
  enableEditing: ()=>{},
  saveChanges: ()=>{},
  delete: ()=>{},

}