import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  Editor, 
  EditorState, 
  RichUtils, 
  getDefaultKeyBinding, 
  convertToRaw, 
  convertFromRaw
} from 'draft-js';

// import CSS
import './MDEditor.css'; //custom css
import 'draft-js/dist/Draft.css'; //default css

// markdown parser methods
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

// import components
import InlineStyleControls from './MDEditor.inline';
import BlockStyleControls from './MDEditor.block';

/**
 * @param {object} props - props from parent component
 * @param {string} props.mdString - empty string || contents from an existing file
 * @param {function} props.getMdString - pass MD string back to parent component
 */
class DraftEditor extends React.Component{
  constructor(props){
    super(props);
    this.state={editorState: EditorState.createEmpty()};
    this.focus = () => this.refs.editor.focus();
    this.onChange=(editorState) => {
      this.setState({editorState});
      // not working properly...
      // this.props.update(draftEditorStateToString(editorState));
      
    };
  }

  _handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      // console.log('on tab');
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType = (blockType) => {
    // console.log(blockType);
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
  }

  _toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  componentDidUpdate(prevProps, prevState){
    // console.log(prevProps);
    // console.log(prevState);
    if(prevProps.mdString !== this.props.mdString){
        
        // ***
        // markdown string to draft raw
        let rawObject = markdownToDraft(this.props.mdString);
        // console.log(rawObject);
        let editorState = EditorState.createWithContent(
          convertFromRaw(rawObject)
        );
        this.setState({editorState});
    }
  }

  save = () => {
    // convert ediorstate to string
    let mdString = draftEditorStateToString(this.state.editorState) || "";
    // console.log(mdString);
    this.props.getMdString(mdString);
    
    // reset its own state, clear the editor content
    this.setState({editorState: EditorState.createEmpty()});
  }

  reset = () => {
    if(this.props.mdString !== ""){
      // markdown string to draft raw
      let rawObject = markdownToDraft(this.props.mdString);
      let editorState = EditorState.createWithContent(
        convertFromRaw(rawObject)
      );
      this.setState({editorState});
    }else{
      this.setState({editorState: EditorState.createEmpty()})
    }
    
  }

  render(){
    // console.log(this.props);
    const {editorState} = this.state;
    // console.log(this.state);

    // ***
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. 
    // Let's just hide it now.

    let className = 'RichEditor-editor';
    let contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div css={css`
        width: 100%;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
      `}>

        <div className="RichEditor-root">
          <BlockStyleControls
            editorState={editorState}
            onToggle={this._toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this._toggleInlineStyle}
          />
          <div className={className} onClick={this.focus}>
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this._handleKeyCommand}
              keyBindingFn={this._mapKeyToEditorCommand}
              onChange={this.onChange}
              placeholder="Start writing ..."
              ref="editor"
              spellCheck={true}
            />
          </div>
        </div>

        <div css={css`padding: 30px 0px;`}>
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-primary"
                onClick={this.save}
              >Save File</button>
            </div>
            <div className="control">
              <button className="button is-warning"
                onClick={this.reset}
              >Reset Contents</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

function draftEditorStateToString(editorState){
  let mdString = "";

  // conver raw state into JS object
  let draftRaw = convertToRaw(editorState.getCurrentContent());

  // convert draft raw (JS object) into Markdown string
  mdString = draftToMarkdown(draftRaw);
  
  return mdString;
}

export default DraftEditor;

DraftEditor.defaultProps = {
  mdString: "",
  getMdString: ()=>"",
}