import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from './firebase.index';
import TagInputEle from '../elements/EditableInput';  

class ManageFileTags extends React.Component{
  constructor(props){
    super(props);
    this.state={
      // file categories
      fileCategories: [],
      // file tags
      filetaglist: [],
      selectedFileTag: "", //updated by enableEditing function
      updateFileTag: {value: "", id: ""}, //updated by enableEditing & updateValue functions
      newFileTag: "",
    }
    this.storeRef = props.firebase.storage.ref();
  }

  componentDidMount(){
    this.props.firebase.filetaglist().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          // console.log(doc.data());
          // console.log(doc.id);
          let obj = {};
          obj.id = doc.id;
          obj.value = doc.data().value;
          _arr.push(obj);
        });

        // console.log(_arr);
        // update state
        this.setState({filetaglist: _arr});
      })
      .catch((error)=>{
        console.log(error);
      })
    
    // fetch in categoires
    this.props.firebase.filecategories().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          // console.log(doc.data());
          _arr.push(doc.data().value);
        });

        // update state
        this.setState({fileCategories: _arr});
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  componentDidUpdate(){
    
    this.props.firebase.filetaglist().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{

          let obj = {};
          obj.id = doc.id;
          obj.value = doc.data().value;

          _arr.push(obj);
        });

        // console.log(_arr);
        // update state only when detecting difference
        let str1 = JSON.stringify(_arr);
        let str2 = JSON.stringify(this.state.filetaglist);
        if(str1 !== str2){
          // update state
          this.setState({filetaglist: _arr});
        }
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  updateValue = (value) => {
    // console.log(value);
    let _obj = {...this.state.updateFileTag};
    _obj.value = value;
    this.setState({updateFileTag: _obj});
  }

  saveChanges = async () => {

    if(this.state.updateFileTag.value !== "" && this.state.updateFileTag.id !== ""){
      
      // update metadata for all files
      let _selectedTag = this.state.selectedFileTag; //previous value
      let _updatedTag = this.state.updateFileTag.value; //updated value

      let _fileCategories = this.state.fileCategories.slice();

      
      for(let cat of _fileCategories){

        let fileRef = this.storeRef.child(cat);

        let _fileList = await getFileList(fileRef);
        // console.log(_fileList);
        
        for(let i = 0; i< _fileList.length; i++){
          let _file = await fetchMetaData(this.storeRef, _fileList[i]);
          // console.log(_file);
          let updated = await updateMetaData(this.storeRef, _file, _selectedTag, _updatedTag);
          // file updated with this tag in metadata
          // can be used to report how many files metadata were updated
          // console.log(updated); 
        }
      }

      // sync the tag list in firestore database
      this.props.firebase.filetaglist().doc(this.state.updateFileTag.id).update({value: this.state.updateFileTag.value})
      .then(()=>{
        console.log('update successful');
        // reset states
        this.setState({
          selectedFileTag: "",
          updateFileTag: {value: "", id: ""}
        })
      })
      .catch((err)=>{
        console.log(err);
      });

    }else{
      console.log('no selection made, can not proceed');
    }
  }

  deleteValue = async (value) => {
    // update metadata for all files
    let _tobeDeletedTag = value.value;
    let _fileCategories = this.state.fileCategories.slice();

    for(let cat of _fileCategories){

      let fileRef = this.storeRef.child(cat);

      let _fileList = await getFileList(fileRef);
      // console.log(_fileList);
      
      for(let i = 0; i< _fileList.length; i++){
        let _file = await fetchMetaData(this.storeRef, _fileList[i]);
        // console.log(_file);
        let updated = await updateMetaData(this.storeRef, _file, _tobeDeletedTag, "");
        // file updated with this tag in metadata
        // can be used to report how many files metadata were updated
        console.log(updated); 
      }
    }

    this.props.firebase.filetaglist().doc(value.id).delete()
      .then(()=>{
        console.log('delete tag successfully');
        // reset states
        this.setState({
          selectedFileTag: "",
          updateFileTag: {value: "", id: ""}
        })
      })
      .catch((err)=>{
        console.log(err);
      });
  }

  // update on which category is becoming editable
  enableEditing = (value) => {
    // console.log(value);
    this.setState({
      selectedFileTag: value.value, //string
      updateFileTag: value, //object {}
    });
  }

  savenewFileTag = () => {
    if(this.state.newFileTag !== ""){
      this.props.firebase.filetaglist().add({value: this.state.newFileTag})
      .then((ref)=>{
        // console.log(ref);
        this.setState({newFileTag: ""})
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  }

  render(){
    // console.log(this.state);
    return(
      <div css={css`
        width: 90%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
      `}>
        <div css={css`
          text-align: center;
          font-size: 1.5rem;
          font-weight: 600;
          padding: 40px 0;
        `}>Manage File Tags</div>

        <div css={css`
          margin: 0 0 40px 0;
        `}>
          <article className="message is-info">
            <div className="message-header">
              <p>Note</p>
            </div>
            <div className="message-body">
              Admin level users can create, modify and delete tags.
            </div>
          </article>
        </div>

        {
          this.state.filetaglist.map((item, index)=>{
            
            return (
              <TagInputEle
                key={index}
                label={`Tag ${index + 1}`}
                value={item}
                buttons={['edit', 'update', 'delete']}
                updatedValue={this.state.updateFileTag}
                updateValue={this.updateValue}
                saveChanges={this.saveChanges}
                delete={this.deleteValue}
                enableEditing={this.enableEditing}
                editable={this.state.selectedFileTag === item.value} />
            )
          })
        }
        
        <div css={css`
          width: 100%;
          padding: 40px 0;
        `}>
          <label className="label">Add a New File Tag</label>
          <div className="field is-grouped">
            <div className="control is-expanded">
              <input className="input" 
                type="text" 
                value={this.state.newFileTag}
                onChange={(e)=>{this.setState({newFileTag: e.target.value})}} />
            </div>
            <div className="control">
              <button className="button is-link"
                onClick={this.savenewFileTag}
              >Save</button>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default withFirebase(ManageFileTags);

const getFileList = async (ref) => {
  let _arr = [];

  await ref.listAll()
  .then((res)=>{
    res.items.forEach((itemRef)=>{
      let obj = {};
      obj.ref = itemRef.fullPath;
      obj.name = itemRef.name.split('_')[0];
      _arr.push(obj);
    });
  })
  .catch((err)=>{
    console.log(err);
  });

  return _arr;
}

const fetchMetaData = async (ref, file) => {
  // console.log(file);
  let obj = {};
  await ref.child(file.ref).getMetadata()
    .then((metadata)=>{
      // console.log(metadata);
      obj.createdAt = metadata.timeCreated;
      obj.updatedAt = metadata.updated;
      obj.tags = metadata.customMetadata !== undefined && metadata.customMetadata.tags ? metadata.customMetadata.tags : null; //set to string || null
      obj = {...obj, ...file}
      // console.log(obj);
    })
    .catch((err)=>{
      console.log(err);
    });
  return obj;
}

const updateMetaData = async (ref, file, _selectedTag, _updatedTag) => {
  // console.log(file);
  let success = false;
  let metaData = {};
  metaData.customMetadata = {};

  if(file.tags){
    let _tags = file.tags.split('_');
    if(_tags.indexOf(_selectedTag) > -1){
      
      if(_updatedTag === ""){
        _tags.splice(_tags.indexOf(_selectedTag), 1);
      }else{
        _tags.splice(_tags.indexOf(_selectedTag), 1, _updatedTag);
      }
      
      let _updatedTagString = _tags.join('_');

      metaData.customMetadata.tags = _updatedTagString;

      await ref.child(file.ref).updateMetadata(metaData)
      .then((metadata)=>{
        // console.log(metadata);
        success = true;
      })
      .catch((err)=>{
        console.log(err.message);
      });
    }
  }else{
    // console.log('this file is not tagged');
  }

  return success; //sucessfully changed metadata for this file
}