import React from 'react';

/**
 * 
 * @param {object} props: data for table head and body 
 * @param {array: string} props.head: table head (simple array)
 * @param {array: object} props.body: table body (objects in array)
 * @param {function} props.onClick: enable editing, return the data of the clicked row
 */

const EditableLabMeetingTable = (props) => {
  return (
    <table className="table is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          {
            props.scheduleData.head.map((item, index)=>{
              return (
                <th key={index}>{item}</th>
              )
            })
          }
          <th key='action-index'>
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {
          props.scheduleData.body.map((item, index)=>{
            return (
              <tr key={index}>
                <td key='date'>{item.date}</td>
                <td key='project'>{item.project}</td>
                <td key='update'>{item.update}</td>
                <td key='room'>{item.room}</td>
                <td key='time'>{item.time}</td>
                <td key='action-index'>
                  <button className="button is-info"
                    onClick={()=>props.onPostpone(item)}
                  >Postpone</button>
                  <span style={{padding: '10px'}}>|</span>
                  <button className="button is-primary"
                    onClick={()=>props.onEdit(item)}
                  >Edit</button>
                  <span style={{padding: '10px'}}>|</span>
                  <button className="button is-warning"
                    onClick={()=>props.onDelete(item)}
                  >Delete</button>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default EditableLabMeetingTable