import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
/**
 * 
 * @param {object} props
 * @param {array} props.elementData.list
 * @param {function} props.save
 * @param {function} props.cancel
 */
class ListOfSubmissionNotesEdit extends React.Component{
  constructor(props){
    super(props);
    this.state={
      list: []
    }
  }

  componentDidMount(){
    this.setState({
      list: [...this.props.elementData.list]
    })
  }

  // may not need this at all...
  // componentDidUpdate(prevProps){
  //   let str1 = JSON.stringify(prevProps.elementData.list);
  //   let str2 = JSON.stringify(this.props.elementData.list);

  //   if(str2 !== str1){
  //     this.setState({...this.props.elementData.list})
  //   }
  // }

  update = (e, index) => {
    let _list = [...this.state.list];
    _list[index] = e.target.value;
    this.setState({
      list: [..._list]
    })
  }

  save = () => {
    let list = [...this.state.list];
    this.props.save(list);
    this.setState({
      list: []
    })
  }

  cancel = () => {
    this.props.cancel();
    this.setState({
      list: []
    })
  }

  addNew = () => {
    let list = [...this.state.list];
    list.push("");
    this.setState({list});
  }

  removeEntry = (index) => {
    // console.log('removing: ' + index);
    let list = [...this.state.list];
    list.splice(index, 1);
    // console.log(list);
    this.setState({list});
  }

  render(){
    return (
      <div>
        <div className="field">
          <div className="control">
            {
              this.state.list.map((note, index)=>{
                return(
                <div key={index} className="field is-grouped">
                  <div className="control is-expanded">
                    <textarea className="textarea is-info"
                      type="text"
                      value={`${note}`}
                      onChange={(e)=>this.update(e, index)} />
                  </div>
                  <div className="control">
                    <span className="button is-warning"
                      onClick={()=>this.removeEntry(index)}>Delete</span>
                  </div>
                </div>
                )
              })
            }
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-primary is-small"
            onClick={this.addNew}>Add a New Entry</button>
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link"
              onClick={this.save}
            >Save Changes</button>
          </div>
          <div className="control">
            <button className="button is-text"
              onClick={this.cancel}
            >Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ListOfSubmissionNotesEdit;