import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from './firebase.context';
// import components
import LabMeetingTable from '../elements/Table.LabMeeting.Edit';
import EditableScheduleModal from '../elements/Modal.LabMeeting';
// import elements
import Selection from '../elements/Select.Simple';

// convert a firestore doc object into an object
// current being used for conversion
const getSingleScheduleDataAsObject = (doc) => {
  let _item = {};
  // date: "Date",
  // project: "Project-In-Progress",
  // update: "Update-Proposal-JournalClub",
  // room: "Room",
  // time: "Time",
  // firestore docRef
  _item.date = doc.data().date || '';
  _item.project = doc.data().project || '';
  _item.update = doc.data().update || '';
  _item.room = doc.data().room || '';
  _item.time = doc.data().time || '';
  _item.id = doc.id || '';
  return _item;
}

class LabMeetingAdminTable extends React.Component {
  constructor(props){
    super(props);
    this.state={
      scheduleData: {...defatulScheduleData}, //default data
      showEditableModal: 'not-active',
      selectedItem: {}
    }
  }

  // *** initial data fetching && loading ***
  componentDidMount(){
    // console.log('admin table component did mount');

    let scheduleData = {};
    let _arr = [];
    let currentYear = new Date().getFullYear();

    // format data for table component header
    scheduleData.head = [
      "Date",
      "Project-In-Progress",
      "Update-Proposal-JournalClub",
      "Room",
      "Time",
    ];

    this.props.firebase.users()
      .orderBy("date")
      .get()
      .then((querySnapshot)=>{
        querySnapshot.forEach((doc)=>{
          let _item = getSingleScheduleDataAsObject(doc);
          _arr.push(_item);
        });

        // ***
        // filter by current year
        let filteredArr = [];

        // console.log(_arr);
        for (let obj of _arr){
          let _year = new Date(obj.date).getFullYear();
          // console.log(_year);

          // ***
          // compare each date with currentDate
          // filter out the out dated ones
          if(_year == currentYear){
            // console.log(_year);
            filteredArr.push(obj);
          }
        }

        // generate table component body
        scheduleData.body = filteredArr;
        this.setState({scheduleData})
      })
      .catch((error)=>{
        // console.log(error);
      })
  }

  // *** data updating && loading based on changes ***
  componentDidUpdate(prevProps, prevState){
    // console.log('admin table component did mount');

    let scheduleData = {};
    let _arr = [];
    let currentYear = new Date().getFullYear();

    // format data for table component header
    scheduleData.head = [
      "Date",
      "Project-In-Progress",
      "Update-Proposal-JournalClub",
      "Room",
      "Time",
    ];

    this.props.firebase.users()
      .orderBy("date")
      .get()
      .then((querySnapshot)=>{
        querySnapshot.forEach((doc)=>{
          // let _item = getSingleScheduleDataAsArray(doc);
          let _item = getSingleScheduleDataAsObject(doc);
          _arr.push(_item);
        });

        // array fetched from firestore
        // console.log(_arr);

        // ***
        // filter by current year
        let filteredArr = [];

        // console.log(_arr);
        for (let obj of _arr){
          let _year = new Date(obj.date).getFullYear();
          // console.log(_year);

          // ***
          // compare each date with currentDate
          // filter out the out dated ones
          if(_year == currentYear){
            // console.log(_year);
            filteredArr.push(obj);
          }
        }

        // console.log(filteredArr);
        // generate table component body
        scheduleData.body = filteredArr;

        // compare prev schduleData with currently fetched scheduleData
        let str1 = JSON.stringify(prevState.scheduleData);
        let str2 = JSON.stringify(scheduleData);

        if(str1 !== str2){
          // console.log('updates detected');
          this.setState({scheduleData});
        }
      })
      .catch((error)=>{
        // console.log(error);
      })
  }

  /**
   * opens the modal with preloaded data: item
   * the value of item is returned from child table component
   * @param {array} item: schedule details for a selected date
   */
  _onEnableEditing = (item) => {
    // console.log(item);
    let _item = {...item};
    this.setState({
      selectedItem: _item,
      showEditableModal: 'is-active'
    })
  }

  // simply open the modal with no preloaded data
  _onAddNewItem = () => {
    let _item = {};
    this.setState({
      selectedItem: _item,
      showEditableModal: 'is-active'
    })
  }

  _onPostpone = (item) => {
    // console.log(item);

    // ***
    // parse date string to date object
    let currentDate = new Date(item.date);
    // console.log(currentDate); 

    // ***
    // calculate 'after 7 days' based on the current date
    let nextDate = currentDate.getDate() + 8;
    currentDate.setDate(nextDate);

    // different date string formats
    // for your convenience
    // console.log(currentDate);
    // console.log(currentDate.toLocaleString()); //7/17/2019, 8:00:00 PM
    // console.log(currentDate.toDateString()); //Wed Jul 17 2019
    // console.log(currentDate.toLocaleDateString()); //7/17/2019
    // or you can get each value individually
    // console.log(currentDate.getDay());
    // console.log(currentDate.getMonth());
    // console.log(currentDate.getFullYear());

    // ***
    // converting to date string from date object
    // convert each value to be consistent with HTML5 date picker value format
    // which is yyyy-mm-dd
    let _month = (currentDate.getMonth() + 1).toString().length > 1 ? (currentDate.getMonth() + 1).toString() : `0${(currentDate.getMonth() + 1).toString()}`;
    // console.log(_month);
    let _date = currentDate.getDate().toString().length > 1 ? currentDate.getDate().toString() : `0${currentDate.getDate().toString()}`;
    // console.log(_date);
    let dateString = `${currentDate.getFullYear()}-${_month}-${_date}`;
    // console.log(dateString);

    let data = {...item};
    data.date = dateString;

    // ***
    // save date string to database
    this.props.firebase.users().doc(data.id)
      .update({...data})
      .then(()=>{
        // console.log(`successfully updated ${data.id}`);
        this.setState({
          selectedItem: {}
        })
      })

  }

  _onDelete = (item) => {
    // console.log('delete the following item');
    // console.log(item);
    this.props.firebase.users().doc(item.id)
      .delete()
      .then(()=>{
        // console.log("Document successfully deleted!");
        this.setState({
          selectedItem: {}
        })
      })
      .catch((error)=>{
        // console.error("Error removing document: ", error);
      });
  }

  /**
   * this function will have to distinguish update existing & add new
   * into firestore database by checking if there is an id property
   * 
   * 1. update: returned data have id property
   * 2. add: returned data doesn't have id property
   */
  _onSaveChanges = (data) => {
    // console.log(data);

    // *** Update an existing schedule at /users***
    if(data.id){
      // console.log(data.id);
      this.props.firebase.users().doc(data.id)
        .update({...data})
        .then(()=>{
          // console.log(`successfully updated ${data.id}`);
          this.setState({
            showEditableModal: 'not-active',
            selectedItem: {}
          })
        })
    }
    // *** Add a new schedule at /users***
    else{
      // console.log(data.id);
      this.props.firebase.users()
        .add({...data})
        .then((doc)=>{
          // console.log("new document added with ID: ", doc.id);
          this.setState({
            showEditableModal: 'not-active',
            selectedItem: {}
          })
        })
        .catch((error)=>{
          // console.error("Error adding document: ", error);
          this.setState({
            showEditableModal: 'not-active',
            selectedItem: {}
          })
        })
    }
  }

  _onCloseModal = () => {
    this.setState({
      showEditableModal: 'not-active',
      selectedItem: {}
    })
  }

  _filterByYear = (data) => {
    // console.log(data);

    let scheduleData = {};
    let _arr = [];
    let selectedYear = parseInt(data.value);

    // format data for table component header
    scheduleData.head = [
      "Date",
      "Project-In-Progress",
      "Update-Proposal-JournalClub",
      "Room",
      "Time",
    ];

    this.props.firebase.users()
      .orderBy("date")
      .get()
      .then((querySnapshot)=>{
        querySnapshot.forEach((doc)=>{
          // let _item = getSingleScheduleDataAsArray(doc);
          let _item = getSingleScheduleDataAsObject(doc);
          _arr.push(_item);
        });

        // array fetched from firestore
        // console.log(_arr);

        // ***
        // filter by current year
        let filteredArr = [];

        // console.log(_arr);
        for (let obj of _arr){
          let _year = new Date(obj.date).getFullYear();
          // console.log(_year);

          // ***
          // compare each date with currentDate
          // filter out the out dated ones
          if(_year == selectedYear){
            // console.log(_year);
            filteredArr.push(obj);
          }
        }

        console.log(filteredArr);
        // generate table component body
        scheduleData.body = filteredArr;
        this.setState({scheduleData})
      })
      .catch((error)=>{
        // console.log(error);
      })
  }

  render(){
    // console.log(this.state.selectedItem);
    console.log(getYearOptions());
    return (
      <div css={css`
        width: 100%;
        padding: 20px 0;
        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 20px 0;
        }
      `}>
        <h2>Lab Meeting Schedule</h2>

        <Selection 
          label="Select year"
          nameAttr="year-selection"
          options={getYearOptions()}
          update = {this._filterByYear}
          defaultItem = {new Date().getFullYear()}
        />
        
        <div className="field">
          <label className="label">Add a new Item</label>
          <div className="control">
            <button className="button is-primary"
              onClick={this._onAddNewItem}
            >Add a new schedule to the table</button>
          </div>
        </div>

        <LabMeetingTable 
          scheduleData={this.state.scheduleData}
          onPostpone={this._onPostpone}
          onEdit={this._onEnableEditing}
          onDelete={this._onDelete} />

        <EditableScheduleModal 
          selectedScheduleData={this.state.selectedItem}
          onClose={this._onCloseModal}
          onSaveChanges={this._onSaveChanges}
          active={this.state.showEditableModal} />
        
      </div>
    )
  }
}

export default withFirebase(LabMeetingAdminTable)

const defatulScheduleData = {
  head: [
    "Date",
    "Project-In-Progress",
    "Update-Proposal-JournalClub",
    "Room",
    "Time",
  ],
  body: [
    {date:'', project:'', update:'', room:'', time:''},
  ]
}

// ***
// format of yearOptions array: ["Select Year", 2019, 2020, etc..]
// default selection is new Date().getFullYear()

const getYearOptions = () => {
  let currentYear = new Date().getFullYear();
  // console.log(typeof(currentYear)); //number

  let yearOptions = ["Select Year"];
  // console.log(currentYear - 2019);

  // ***
  // giving it one more year than the current year
  for (let i = 0; i <= (currentYear - 2019 + 1); i++){
    // console.log(i);
    yearOptions.push(2019 + i);
  }
  return yearOptions;
}