import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
/**
 * 
 * @param {object} props
 * @param {funnction} props.openEditingModal
 */
const ActionButtonsForEditingMSRequestForm = (props) => {
  // console.log(props);
  return(
    
    <div>
      <button className="button is-primary"
        onClick={()=>props.openEditingModal(props.elementData)}>Edit</button>
    </div>
  )
}

export default ActionButtonsForEditingMSRequestForm;