import React from 'react';

/**
 * 
 * @param {object} props
 * @param {object} props.elementData
 * @param {string} props.elementData.title
 * @param {array} props.elementData.instructions 
 * @param {function} props.save
 * @param {function} props.cancel
 */

class ListOfMSRequestInstructionsEdit extends React.Component {
  constructor(props){
    super(props);
    this.state={
      title: "",
      instructions: []
    }
  }

  componentDidMount(){
    this.setState({...this.props.elementData});
  }

  // may not needed at all...
  // componentDidUpdate(prevProps){
  //   console.log('component did update');
  //   console.log(prevProps.elementData);
  //   console.log(this.props.elementData);
  //   let str1 = JSON.stringify(prevProps.elementData);
  //   let str2 = JSON.stringify(this.props.elementData);

  //   if(str2 !== str1){
  //     this.setState({...this.props.elementData})
  //   }
  // }

  updateTitle = (e) => {
    let value = e.target.value;
    this.setState({title: value});
  }

  updateInstructions = (e, index) => {
    let instructions = [...this.state.instructions];
    instructions[index] = e.target.value;
    this.setState({instructions});
  }

  save = () => {
    let obj = {...this.props.elementData}; // clone other properties too
    obj.title = this.state.title;
    obj.instructions = [...this.state.instructions];
    this.props.save(obj);
    this.setState({
      title: "",
      instructions: []
    })
  }

  cancel = () => {
    this.props.cancel();
    this.setState({
      title: "",
      instructions: []
    })
  }

  addNew = () => {
    let instructions = [...this.state.instructions];
    instructions.push("");
    this.setState({instructions});
  }

  removeEntry = (index) => {
    // console.log('removing: ' + index);
    let instructions = [...this.state.instructions];
    instructions.splice(index, 1);
    // console.log(instructions);
    this.setState({instructions});
  }

  render(){
    return (
      <div>
        <article className="message is-info">
          <div className="message-header">
              <input className="input is-info" 
                type="text" 
                value={this.state.title}
                onChange={this.updateTitle} />
            <button className="delete" aria-label="delete"></button>
          </div>
          <div className="message-body">
            <ul>
              {
                this.state.instructions.map((item, index)=>{
                  return (
                    <div key={index} className="field is-grouped">
                      <div className="control is-expanded">
                        <textarea className="textarea is-info"
                          type="text"
                          value={`${item}`}
                          onChange={(e)=>this.updateInstructions(e, index)} />
                      </div>
                      <div className="control">
                        <span className="button is-warning"
                          onClick={()=>this.removeEntry(index)}>Delete</span>
                      </div>
                    </div>
                  )
                })
              }
            </ul>
          </div>
        </article>

        <div className="field">
          <div className="control">
            <button className="button is-primary is-small"
            onClick={this.addNew}>Add a New Entry</button>
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link"
              onClick={this.save}
            >Save Changes</button>
          </div>
          <div className="control">
            <button className="button is-text"
              onClick={this.cancel}
            >Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ListOfMSRequestInstructionsEdit;