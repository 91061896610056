import React from 'react';

/**
 * @param {object} props
 * @param {object} props.elementData
 * @param {string} props.elementData.label
 * @param {string} props.elementData.help
 * @param {string} props.elementData.placeholder
 * @param {string} props.elementData.nameAttr
 * @param {function} props.save
 * @param {function} props.cancel
 */
class InputElementAsTextEdit extends React.Component {
  constructor(props){
    super(props);
    this.state={
      label: "",
      help: "",
      placeholder: "",
      nameAttr: "" //not being modified
    }
  }

  componentDidMount(){
    this.setState({...this.props.elementData})
  }

  update = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value
    })
  }

  save = () => {
    let _data = {...this.state};
    this.props.save(_data);
    this.setState({
      label: "",
      help: "",
      placeholder: "",
      nameAttr: ""
    })
  }

  cancel = () => {
    this.props.cancel();
    this.setState({
      label: "",
      help: "",
      placeholder: "",
      nameAttr: ""
    })
  }

  render(){
    return (
      <div>
        <div className="field">
          <div className="control">
            <label className="label">Label</label>
            <input className="input"
              type="text"
              name="label"
              value={this.state.label}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">Help message (optional)</label>
            <input className="input"
              type="text"
              name="help"
              value={this.state.help}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">Placeholder message (optional)</label>
            <input className="input" 
              type="text"
              name="placeholder"
              value={this.state.placeholder}
              onChange={this.update} 
            />
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link"
              onClick={this.save}
            >Save Changes</button>
          </div>
          <div className="control">
            <button className="button is-text"
              onClick={this.cancel}
            >Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default InputElementAsTextEdit;