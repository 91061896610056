import React from 'react';

/**
 * 
 * @param {object} props
 * @param {string} props.active: is-active vs not-active
 * @param {array} props.selectedScheduleData: the data of selected schedule item
 * @param {function} props.onClose: simply close modal
 * @param {function} props.onSaveChanges: save changes
 */
const ModalForEditingLabMeetingSchedule = (props) => {
  // console.log(props);
  return (
    <div className={`modal ${props.active}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit</p>
          <button className="delete" 
            aria-label="close"
            onClick={props.onClose}></button>
        </header>
        <section className="modal-card-body">
          <ScheduleUpdateForm 
            data={props.selectedScheduleData}
            save={props.onSaveChanges}
            cancel={props.onClose} />
        </section>
      </div>
    </div>
  )
}

const FORMDATA = {
  date: "",
  project: "",
  update: "",
  room: "",
  time: "",
  id: ""
}

/**
 * the class component has its own internal state
 * it also converts array input into object
 * 
 * @input {array}
 * @state {object}
 * 
 * @param {object} state
 * @param {string} state.date
 * @param {string} state.project
 * @param {string} state.update
 * @param {string} state.room
 * @param {string} state.time 
 * @param {string} state.id 
 */
class ScheduleUpdateForm extends React.Component{
  constructor(props){
    super(props);
    this.state={...FORMDATA}
  }

  componentDidUpdate(prevProps){
    // console.log('component did update');
    let str1 = JSON.stringify(prevProps.data);
    let str2 = JSON.stringify(this.props.data);
    // make a copy
    let _data = {...this.props.data};
    // console.log(_data);
    // update state using the copy
    if(str2 !== str1){
      this.setState({..._data})
    }
  }

  update = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({[name]: value});
  }

  save = () => {
    // console.log(this.state);
    if(this.state.date === "" || this.state.project === "" || this.state.update === "" || this.state.room === "" || this.state.time === ""){
      alert('all fields are required');
    }else{   
      this.props.save({...this.state});
      this.setState({...FORMDATA})
    }
  }

  cancel = () => {
    this.props.cancel();
    this.setState({...FORMDATA})
  }

  render(){
    // console.log(this.state);
    return(
      <div>
        <div className="field">
          <label className="label">Date</label>
          <div className="control">
            <input className="input" type="date" placeholder="Text input" 
              name="date"
              value={this.state.date}
              onChange={this.update}/>
          </div>
        </div>

        <div className="field">
          <label className="label">Project-In-Progress</label>
          <div className="control">
            <input className="input" type="text" placeholder="John Dow" 
              name="project"
              value={this.state.project}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <label className="label">Update-Proposal-JournalClub</label>
          <div className="control">
            <input className="input" type="text" placeholder="John Dow"
              name="update"
              value={this.state.update}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <label className="label">Room</label>
          <div className="control">
            <input className="input" type="text" placeholder="4161" 
              name="room"
              value={this.state.room}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <label className="label">Time</label>
          <div className="control">
            <input className="input" type="text" placeholder="11:00AM - 13:00PM" 
              name="time"
              value={this.state.time}
              onChange={this.update} />
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link"
              onClick={this.save}
            >Save Changes</button>
          </div>
          <div className="control">
            <button className="button is-text"
              onClick={this.cancel}
            >Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalForEditingLabMeetingSchedule