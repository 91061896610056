import React from 'react';
// import firebase
import { withFirebase } from './firebase.index'
// import components
import FileEditor from './file.editor';
import SelectEle from '../elements/Select.Simple';

class CreateNewLabFile extends React.Component {
  constructor(props){
    super(props);
    this.state={
      filename: "", //current file name
      fileCategories: [], //simple array
      filecategory: "", //string
      errMsg: null,
      errMsgFileCategory: null, // check on file category value
      notification: null, //notification: null || {type: '', msg: ''}
    };
    this.dbCategoryRef = this.props.firebase.filecategories();
    this.dbTagRef = this.props.firebase.filetaglist();
    this.storeRef = props.firebase.storage.ref();
  }

  componentDidMount(){
 
    // fetch in categoires
    this.props.firebase.filecategories().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          // console.log(doc.data());
          _arr.push(doc.data().value);
        });

        // update state
        this.setState({fileCategories: _arr});
      })
      .catch((error)=>{
        // console.log(error);
      })
  }

  componentDidUpdate(){

    // fetch in categories
    this.props.firebase.filecategories().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          _arr.push(doc.data().value);
        });

        // console.log(_arr);
        // update state only when detecting difference
        let str1 = JSON.stringify(_arr);
        let str2 = JSON.stringify(this.state.fileCategories);
        if(str1 !== str2){
          // update state
          this.setState({fileCategories: _arr});
        }
      })
      .catch((error)=>{
        // console.log(error);
      })
  }

  // ***
  // triggered after clicking the save button
  saveMdStringToStorage = (mdString) => {
    // console.log(mdString);

    if(this.state.errMsg){
      console.log('error, save denied');
    }
    else if(this.state.filename === ""){
      this.setState({
        errMsg: `File name can not be empty`
      })
    }else if(this.state.filecategory === ""){
      this.setState({
        errMsgFileCategory: `Please select a file category`
      })
    }
    else{
      // replace #, [, ], *, ? or space characters to -
      let fileName = this.state.filename.replace(/[\#\[\]\*\?\ ]/g, '-');

      // obtain a unique time string
      let timeStr = getCurrentTimeString() || '';

      // create firebase cloud storage reference point
      // concat file name and time string with '_'
      let labFileRef = this.storeRef.child(`${this.state.filecategory}/${fileName}_${timeStr}.md`);

      // save string to file
      labFileRef.putString(mdString)
      .then((snapshot)=>{
        // console.log(snapshot);
        // console.log(snapshot.metadata.name);
        this.setState({
          filename:'',
          filecategory: '',
          errMsg: null,
          notification: {
            type: 'is-success',
            msg: `File successfully saved as ${snapshot.metadata.name} under category ${this.state.filecategory}.`
          }
        });
      })
      .catch((err)=>{
        // console.log(err); //err is not a string
        this.setState({
          filename:'',
          filecategory: '',
          errMsg: null,
          notification: {
            type: 'is-danger',
            msg: err.message
          }
        });
      });

    }
  }

  // ***
  // update file name based on user input
  onHandleFileName = (e) => {
    let fileName = e.target.value;

    if(fileName.match(/[\#\[\]\*\?]/g)){
      this.setState({
        fileName: fileName,
        errMsg: `File name can not contain #, [, ], *, or ? character`,
      })
    }else if (fileName === ""){
      this.setState({
        filename: fileName,
        errMsg: `File name can not be empty`
      })
    }else{
      this.setState({
        filename: fileName,
        errMsg: null,
      })
    }
  }

  // ***
  // update file category based on user selection
  updateCategory = (category) => {
    // console.log(category);
    this.setState({
      filecategory: category.value,
      errMsgFileCategory: null
    });
  }

  // ***
  // manually close bottom notification box if there is one
  closeNotification = () => {
    this.setState({notification: null});
  }

  render() {
    // console.log(this.state);
    return (
      <div>
        <div className="field">
          <label className="label">File Name (required)</label>
          <div className="control">
            <input className={this.state.errMsg ? "input is-danger": "input"} 
              type="text" 
              value={this.state.filename}
              placeholder="sample lab file" 
              onChange={this.onHandleFileName} />
            <p className="help is-danger">{this.state.errMsg}</p>
          </div>
        </div>

        <SelectEle 
          label="File Category (required)"
          help="Select under which category the file will be saved, only ONE category per file allowed"
          nameAttr="file-category"
          errMsg={this.state.errMsgFileCategory}
          options={['Select a Category', ...this.state.fileCategories]}
          selectedOption={this.state.filecategory} //only used to reset child component state
          update={this.updateCategory}
        />

        <FileEditor 
          getMdString={this.saveMdStringToStorage}
        />
        
        {
          this.state.notification
          &&
          <div>
            <div className={`notification ${this.state.notification.type}`}>
              <button className="delete"
                onClick={this.closeNotification}
              ></button>
              {this.state.notification.msg}
            </div>  
          </div>
        }
      
      </div>
    )
  }
}

function getCurrentTimeString(){
  // console.log(new Date().toLocaleDateString());
  let str1 = new Date().toLocaleDateString().replace(/[\/]/g, '-');
  // console.log(str1);
  // console.log(new Date().toLocaleTimeString());
  let str2 = new Date().toLocaleTimeString().replace(/[\:\ ]/g, '-');
  // console.log(str2);
  let str = `${str1}-${str2}`;
  return str;
}

export default withFirebase(CreateNewLabFile);