import React from 'react';

/**
 * 
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.help: optional
 * @param {string} props.nameAttr
 * @param {array} props.options
 * @param {string} props.value NOT needed for admin
 * @param {function} props.save
 * @param {function} props.cancel
 */

class SelectElementWithInputEdit extends React.Component {
  constructor(props){
    super(props);
    this.state={
      label: "",
      help: "",
      options: [],
      nameAttr: "", //not being modified
      value: "", //not being modified
    }
  }

  componentDidMount(){
    let _data = JSON.parse(JSON.stringify(this.props.elementData));
    this.setState({..._data});
  }

  update = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({[name]: value});
  }

  updateOptions = (e, index) => {
    let options = [...this.state.options];
    options[index] = e.target.value;
    this.setState({options});
  }

  save = () => {
    let _data = JSON.parse(JSON.stringify(this.state));
    this.props.save(_data);
    this.setState({
      label: "",
      help: "",
      options: [],
      nameAttr: "", //not being modified
      value: "", //not being modified
    })
  }

  cancel = () => {
    this.props.cancel();
    this.setState({
      label: "",
      help: "",
      options: [],
      nameAttr: "", //not being modified
      value: "", //not being modified
    })
  }

  addNew = () => {
    let options = [...this.state.options];
    options.pop();
    options.push("");
    options.push("Others");
    this.setState({options});
  }

  removeEntry = (index) => {
    // console.log('removing: ' + index);
    let options = [...this.state.options];
    options.splice(index, 1);
    // console.log(options);
    this.setState({options});
  }

  render(){
    return (
      <div>
        <div className="field">
          <div className="control">
            <label className="label">Label</label>
            <input className="input"
              type="text"
              name="label"
              value={this.state.label}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <label className="label">Help message (optional)</label>
            <input className="input"
              type="text"
              name="help"
              value={this.state.help}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <label className="label">Options</label>
          {
            this.state.options.map((option, index)=>{
              return(
              <div key={index} className="field is-grouped">
                {
                  index === 0
                  &&
                  <>
                  <div className="control">
                    <label className="label">Seletion title (required)</label>
                  </div>
                  <div className="control is-expanded">
                    <input className="input"
                      type="text"
                      value={`${option}`}
                      onChange={(e)=>this.updateOptions(e, index)} />
                  </div>
                  <div className="control">
                    <span className="button is-warning" disabled>Delete</span>
                  </div>
                  </>
                }
                {
                  index > 0 && index < (this.state.options.length - 1)
                  &&
                  <>
                  <div className="control">
                    <label className="label">{`option ${index}`}</label>
                  </div>
                  <div className="control is-expanded">
                    <input className="input"
                      type="text"
                      value={`${option}`}
                      onChange={(e)=>this.updateOptions(e, index)} />
                  </div>
                  <div className="control">
                    <span className="button is-warning"
                      onClick={()=>this.removeEntry(index)}>Delete</span>
                  </div>
                  </>
                }
                {
                  index === this.state.options.length - 1
                  &&
                  <>
                  <div className="control">
                    <label className="label">{`option ${index}`}</label>
                  </div>
                  <div className="control is-expanded">
                    <input className="input"
                      type="text"
                      value={`${option}`}
                      disabled />
                  </div>
                  <div className="control">
                    <span className="button is-warning" disabled>Delete</span>
                  </div>
                  </>
                }
              </div>
              )
            })
          }
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-primary is-small"
            onClick={this.addNew}>Add a New Entry</button>
          </div>
        </div>
        
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link"
              onClick={this.save}
            >Save Changes</button>
          </div>
          <div className="control">
            <button className="button is-text"
              onClick={this.cancel}
            >Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default SelectElementWithInputEdit;