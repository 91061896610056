import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from './firebase.context';
// import components
import ListOfMSRequestInstructions from '../elements/List.MSRequestInstructions';
import InputElementAsRadio from '../elements/Input.Radio';
import InputElementAsText from '../elements/Input.Text';
import InputElementAsTextArea from '../elements/Input.TextArea';
import ListOfSubmissionNotes from '../elements/List.SubmissionNotes';
import SimpleSelectElement from '../elements/Select.Simple';
import SelectElementWithInput from '../elements/Select.WithInput';
import ActionButtonsForEditingMSRequestForm from '../elements/ActionButtons.MSRequest.Edit';
import EditingModalForMSRequestForm from '../elements/Modal.MSRequest.Edit';
// import default data (not used in production)
import {defaultRequestFormData, updateRequestFormData} from '../constants/msRequestFormData';

const MSRequestComponent = (props) => {
  return (
    <div css={css`
      width: 100%;
      padding: 20px 0;
      h2 {
        font-size: 1.5rem;
        font-weight: bold;
        padding: 20px 0;
      }
    `}>
      <h2>Edit MS Request Form</h2>
      <MSRequestForm firebase={props.firebase}/>
    </div>
  )
}

class MSRequestForm extends React.Component{
  constructor(props){
    super(props);
    this.state={
      msRequestFormData: [], //array
      showEditingModal: 'not-active', //string
      selectedElementForEditing: {}, //object
    }
  }

  componentDidMount(){
    // console.log("ms request admin form did mount");
    this.props.firebase.msrequestform().get()
    .then((querySnapshot)=>{
      querySnapshot.forEach((doc)=>{
        let _data = JSON.parse(JSON.stringify(doc.data().data));
        this.setState({msRequestFormData: _data});
      })
    })
    .catch((error)=>{
      console.log(error);
    });
  }

  // not used here
  update = (value) => {
    // this function has no practical use in admin section
    // console.log('update values');
    // console.log(value);
  }

  openEditingModal = (data) => {
    // console.log(data);
    this.setState({
      showEditingModal: 'is-active',
      selectedElementForEditing: {...data}
    })
  }

  closeEditingModal = () => {
    this.setState({
      showEditingModal: 'not-active',
      selectedElementForEditing: {}
    })
  }

  // save changes made on elements
  save = (data) => {
    // console.log(data);// save to database
    let _data = JSON.parse(JSON.stringify(data)); // data is an object
    let _array = JSON.parse(JSON.stringify(this.state.msRequestFormData)); //array

    _array.forEach((element, index) => {
      if(element.nameAttr === _data.nameAttr){
        _array[index] = _data;
      }
    });

    this.setState({
      msRequestFormData: _array,
      showEditingModal: 'not-active',
      selectedElementForEditing: {}
    });

    // update formData in database {data: []}
    this.props.firebase.msrequestform().doc('formData').set({data: _array})
    .then(()=>{
      // console.log('update to database successful');
    })
    .catch(err=>{
      // console.log(err);
    });
  }

  udpateFormFromFile = () => {
    let _array = JSON.parse(JSON.stringify(updateRequestFormData));
    // this action will overwrite the existing formdata in database
    this.props.firebase.msrequestform().doc('formData').set({data: _array})
    .then(()=>{
      console.log('update formData from file to database successful');
      this.setState({msRequestFormData: _array});
    })
    .catch(err=>{
      console.log(err);
    });
  }

  render(){

    // *** element types with matching elements***
    // type: 'list-simple' -> render: <ListOfSubmissionNotes>
    // type: 'list-messageboard' -> render: <ListOfMSRequestInstructions>
    // type: 'input-radio' -> render: <InputElementAsRadio>
    // type: 'input-text' -> render: <InputElementAsText>
    // type: 'select-simple' -> render: <SimpleSelectElement>
    // type: 'select-withinput' -> render: <SelectElementWithInput>
    // type: 'input-area' -> render: <InputElementAsTextArea>

    // deep clone of msRequestFormData
    let data = JSON.parse(JSON.stringify(this.state.msRequestFormData));
    // console.log(data);
    return(
      <div>

        {
          data.map((_elementData, index)=>{

            return(
              <div key={index} css={css`margin: 20px 0;`}>
              {
                _elementData.type === 'list-simple'
                &&
                <ListOfSubmissionNotes 
                  list={_elementData.list}
                  nameAttr={_elementData.nameAttr}
                />
              }
              {
                _elementData.type === 'list-messageboard'
                &&
                <ListOfMSRequestInstructions 
                  title={_elementData.title}
                  instructions={_elementData.instructions}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              {
                _elementData.type === 'input-radio'
                &&
                <InputElementAsRadio 
                  label={_elementData.label}
                  help={_elementData.help}
                  optionA={_elementData.optionA}
                  optionB={_elementData.optionB}
                  nameAttr={_elementData.nameAttr}
                />
              }
              {
                _elementData.type === 'input-text'
                &&
                <InputElementAsText 
                  label={_elementData.label}
                  help={_elementData.help}
                  placeholder={_elementData.placeholder}
                  nameAttr={_elementData.nameAttr}
                />
              }
              {
                _elementData.type === 'input-area'
                &&
                <InputElementAsTextArea 
                  label={_elementData.label}
                  help={_elementData.help}
                  placeholder={_elementData.placeholder}
                  nameAttr={_elementData.nameAttr}
                />
              }
              {
                _elementData.type === 'select-simple'
                &&
                <SimpleSelectElement 
                  label={_elementData.label}
                  value={_elementData.value}
                  help={_elementData.help}
                  options={_elementData.options}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
              {
                _elementData.type === 'select-withinput'
                &&
                <SelectElementWithInput 
                  label={_elementData.label}
                  value={_elementData.value}
                  help={_elementData.help}
                  options={_elementData.options}
                  nameAttr={_elementData.nameAttr}
                  update={this.update}
                />
              }
                <ActionButtonsForEditingMSRequestForm 
                  elementData={_elementData}
                  openEditingModal={this.openEditingModal}
                />
              </div>
            )
            
          })
        }

        <EditingModalForMSRequestForm 
          selectedElementForEditing={this.state.selectedElementForEditing}
          active={this.state.showEditingModal}
          onClose={this.closeEditingModal}
          onSave={this.save}
        />
        
        <h2>Reset MS Request Form</h2>
        <article className="message">
          <div className="message-header">
            <p>Note</p>
          </div>
          <div className="message-body">
            Performing the following action will <strong>reset</strong> the form to its default and <strong>discard</strong> all changes you have made in the past.
          </div>
        </article>
        <button className="button is-warning"
          onClick={this.udpateFormFromFile}
        >Dangerously Reset the Form</button>
        
      </div>
    )
  }
}

export default withFirebase(MSRequestComponent);



