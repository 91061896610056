import React from 'react';

/**
 * 
 * @param {object} props
 * @param {string} props.active: is-active vs not-active
 * @param {function} props.editCancel: simply close modal
 * @param {function} props.editSubmit: save changes
 */
const ModalForMSStatus = (props) => {
  // console.log(props);
  return (
    <div className={`modal ${props.active}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit MS Status</p>
          <button className="delete" 
            aria-label="close"
            onClick={props.editCancel}></button>
        </header>
        <section className="modal-card-body">
          <MSStatusForm
            selectedItem={props.selectedItem}
            submit={props.editSubmit}
            cancel={props.editCancel} />
        </section>
      </div>
    </div>
  )
}

const FORMDATA = {
  equipment: "",
  status: "",
  id: ""
}

/**
 * the class component has its own internal state
 * it also converts array input into object
 * 
 * @param {object} state
 * @param {string} state.equipment
 * @param {string} state.status
 * @param {string} state.id
 */
class MSStatusForm extends React.Component{
  constructor(props){
    super(props);
    this.state={...FORMDATA}
  }

  componentDidUpdate(prevProps){
    // console.log('component did update');
    let str1 = JSON.stringify(prevProps.selectedItem);
    let str2 = JSON.stringify(this.props.selectedItem);

    // update state using the copy
    if(str2 !== str1){
      this.setState({...this.props.selectedItem})
    }
  }

  update = (e) => {
    console.log(e.target.value);
    let name = e.target.name;
    let value = e.target.value;
    this.setState({[name]: value});
  }

  submit = () => {
    if(this.state.equipment === "" || this.state.status === ""){
      alert('all fields are required');
    }else{
      this.props.submit({...this.state});
      this.setState({...FORMDATA});
    }
  }

  cancel = () => {
    this.props.cancel();
    this.setState({...FORMDATA})
  }

  render(){
    // console.log(this.state);
    return(
      <div>
        <div className="field">
          <label className="label">Equipment</label>
          <div className="control">
            <input className="input" type="text" placeholder="QE Turbo" 
              name="equipment"
              value={this.state.equipment}
              onChange={this.update} />
          </div>
        </div>

        <div className="field">
          <label className="label">Status</label>
          <div className="control">
            <div className="select">
              <select
                name="status" 
                value={this.state.status}
                onChange={this.update}>
                <option>Select Status</option>
                <option>Running</option>
                <option>Down</option>
              </select>
            </div>
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link"
              onClick={this.submit}
            >Submit</button>
          </div>
          <div className="control">
            <button className="button is-text"
              onClick={this.cancel}
            >Cancel</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalForMSStatus