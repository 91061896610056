import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from './firebase.index';
import FileCategoryItem from '../elements/EditableInput';  

class ManageFileCategories extends React.Component{
  constructor(props){
    super(props);
    this.state={
      filecategories: [],
      selectedFileCategory: "", //updated by enableEditing function
      updateFileCategory: {}, //updated by enableEditing & updateValue functions
      newFileCategory: "",
    }
    this.dbRef = this.props.firebase.filecategories();
  }

  componentDidMount(){
    this.dbRef.get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          // console.log(doc.data());
          // console.log(doc.id);
          let obj = {};
          obj.id = doc.id;
          obj.value = doc.data().value;
          _arr.push(obj);
        });

        // console.log(_arr);
        // update state
        this.setState({filecategories: _arr});
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  componentDidUpdate(){
    
    this.dbRef.get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{

          let obj = {};
          obj.id = doc.id;
          obj.value = doc.data().value;

          _arr.push(obj);
        });

        // console.log(_arr);
        // update state only when detecting difference
        let str1 = JSON.stringify(_arr);
        let str2 = JSON.stringify(this.state.filecategories);
        if(str1 !== str2){
          // update state
          this.setState({filecategories: _arr});
        }
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  updateValue = (value) => {
    // console.log(value);
    let _obj = {...this.state.updateFileCategory};
    _obj.value = value;
    this.setState({updateFileCategory: _obj});
  }

  saveChanges = () => {

    if(this.state.updateFileCategory.value !== "" && this.state.updateFileCategory.id !== ""){
      this.dbRef.doc(this.state.updateFileCategory.id).update({value: this.state.updateFileCategory.value})
      .then(()=>{
        console.log('update successful');
        // reset states
        this.setState({
          selectedFileCategory: "",
          updateFileCategory: {}
        })
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  }

  // update on which category is becoming editable
  enableEditing = (value) => {
    // console.log(value);
    this.setState({
      selectedFileCategory: value.value, //string
      updateFileCategory: value, //object {}
    });
  }

  saveNewFileCategory = () => {
    if(this.state.newFileCategory !== ""){
      this.dbRef.add({value: this.state.newFileCategory})
      .then((ref)=>{
        // console.log(ref);
        this.setState({newFileCategory: ""})
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  }

  render(){
    return(
      <div css={css`
        width: 90%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
      `}>
        <div css={css`
          text-align: center;
          font-size: 1.5rem;
          font-weight: 600;
          padding: 40px 0;
        `}>Manage File Categories</div>

        <div css={css`
          margin: 0 0 40px 0;
        `}>
          <article className="message is-info">
            <div className="message-header">
              <p>Note</p>
            </div>
            <div className="message-body">
              Admin users can <strong>ONLY</strong> add new categories.
              Editing and Deletion are not available at this stage.
            </div>
          </article>
        </div>
        {
          this.state.filecategories.map((item, index)=>{
            
            return (
              <FileCategoryItem 
                key={index}
                label={`Category ${index + 1}`}
                value={item}
                buttons={[]} //no buttons rendered
                updatedValue={this.state.updateFileCategory}
                updateValue={this.updateValue}
                saveChanges={this.saveChanges}
                enableEditing={this.enableEditing}
                editable={this.state.selectedFileCategory === item.value} />
            )
          })
        }
        
        <div css={css`
          width: 100%;
          padding: 40px 0;
        `}>
          <label className="label">Add a New Category</label>
          <div className="field is-grouped">
            <div className="control is-expanded">
              <input className="input" 
                type="text" 
                value={this.state.newFileCategory}
                onChange={(e)=>{this.setState({newFileCategory: e.target.value})}} />
            </div>
            <div className="control">
              <button className="button is-link"
                onClick={this.saveNewFileCategory}
              >Save</button>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default withFirebase(ManageFileCategories);