import React from 'react';
// import components
import ListOfMSRequestInstructionsEdit from './List.MSRequestInstructions.Edit';
import ListOfSubmissionNotesEdit from './List.SubmissionNotes.Edit';
import InputElementAsRadioEdit from './Input.Radio.Edit';
import InputElementAsTextEdit from './Input.Text.Edit';
import InputElementAsTextAreaEdit from './Input.TextArea.Edit';
import SimpleSelectElementEdit from './Select.Simple.Edit';
import SelectElementWithInputEdit from './Select.WithInput.Edit';

/**
 * @param {object} props
 * @param {string} props.active
 * @param {function} props.onClose
 * 
 * element types:
 * list-simple
 * list-messageboard
 * input-radio
 * input-text
 * input-area
 * select-simple
 * select-withinput
 */
class EditingModalForMSRequestForm extends React.Component{

  render(){
    // console.log(this.props);
    return(
      <div className={`modal ${this.props.active}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit</p>
            <button className="delete" 
              aria-label="close"
              onClick={this.props.onClose}></button>
          </header>
          <section className="modal-card-body">
            {
              Object.keys(this.props.selectedElementForEditing).length > 0
              &&
              <div>
              {
                this.props.selectedElementForEditing.type === 'list-simple'
                &&
                <ListOfSubmissionNotesEdit 
                  elementData={this.props.selectedElementForEditing}
                  save={this.props.onSave}
                  cancel={this.props.onClose}
                />
              }
              {
                this.props.selectedElementForEditing.type === 'list-messageboard'
                &&
                <ListOfMSRequestInstructionsEdit 
                  elementData={this.props.selectedElementForEditing}
                  save={this.props.onSave}
                  cancel={this.props.onClose} 
                />
              }
              {
                this.props.selectedElementForEditing.type === 'input-radio'
                &&
                <InputElementAsRadioEdit 
                  elementData={this.props.selectedElementForEditing}
                  save={this.props.onSave}
                  cancel={this.props.onClose}
                />
              }
              {
                this.props.selectedElementForEditing.type === 'input-text'
                &&
                <InputElementAsTextEdit 
                  elementData={this.props.selectedElementForEditing}
                  save={this.props.onSave}
                  cancel={this.props.onClose}
                />
              }
              {
                this.props.selectedElementForEditing.type === 'input-area'
                &&
                <InputElementAsTextAreaEdit 
                  elementData={this.props.selectedElementForEditing}
                  save={this.props.onSave}
                  cancel={this.props.onClose}
                />
              }
              {
                this.props.selectedElementForEditing.type === 'select-simple'
                &&
                <SimpleSelectElementEdit 
                  elementData={this.props.selectedElementForEditing}
                  save={this.props.onSave}
                  cancel={this.props.onClose}
                />
              }
              {
                this.props.selectedElementForEditing.type === 'select-withinput'
                &&
                <SelectElementWithInputEdit 
                  elementData={this.props.selectedElementForEditing}
                  save={this.props.onSave}
                  cancel={this.props.onClose}
                />
              }
              </div>
            }
            {
              Object.keys(this.props.selectedElementForEditing).length === 0
              &&
              <div>No data...</div>
            }
           
          </section>
        </div>
      </div>
    )
  }
}

export default EditingModalForMSRequestForm;