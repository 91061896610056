import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

/**
 * 
 * @param {object} props
 * @param {string} props.label - lable for the tag group
 * @param {string} props.help - optional, help message
 * @param {array} props.tags - array of tag objects
 * @param {object} props.tags[i] - tag object
 * @param {string} props.tags[i].value - value attr 
 * @param {string} props.tags[i].name - name attr
 * @param {string} props.tags[i].label - tag label
 * @param {array} props.checkedTags - simple array of checked tags
 * @param {function} props.update - return selected tag array, currently only return value attr, can add name attr in return values if needed
 */

 
const SimpleCheckBox = (props) => {

  return(
    <div className="field">
      <label className="label">{props.label}</label>
      <p css={css`
        font-size: 0.9rem;
        color: #283593;
        padding: 0px 0px 10px 0px;
      `}>{props.help}</p>
      <div className="control">
        {
          props.tags.map((tag, index)=>{
            return(
              <label key={index} className="checkbox is-unselectable">
                <input type="checkbox"
                  value={tag.value}
                  name={tag.nameAttr}
                  checked={props.checkedTags.indexOf(tag.value)>-1}
                  onChange={(e)=>props.update(e.target.value)}
                />
                <span css={css`
                  font-size: 0.9rem;
                  color: #fafafa;
                  padding: 4px 10px; 
                  margin: 0px 10px; 
                  background-color: #209cee;
                  border-radius: 4px;
                `}>{tag.label}</span>
              </label>
            )
          })
        }
      </div>
    </div>
  )
}

export default SimpleCheckBox;

SimpleCheckBox.defaultProps = {
  label: "",
  help: "",
  tags: [],
  update: ()=>[]
}