import React from "react"
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
// import components
import Layout from "../components/layout"
import Menu from "../components/menu.admin";
import LabMeetingAdmin from "../components/table.labmeeting.admin";
import MSStatusUpdateAdmin from "../components/table.msstatus.admin";
import MSRequestFormAdmin from "../components/form.msrequest.admin";
import MSBookingAdmin from '../components/table.msbooking.admin';
import ManageFiles from '../components/file.managefiles';
import ManageFileCategories from '../components/file.managefilecategories';
import ManageFileTags from '../components/file.managefiletags';
import CreateNewFiles from '../components/file.createnewfiles';

// *** link activation values
// *** the values are used to determine which component to render
// lab-meeting-schedule
// ms-status
// ms-booking
// ms-request-form
// manage-files
// manage-file-categories
// manage-file-tags
// create-new-files

class AdminPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeMenu: 'lab-meeting-schedule',
    }
  }

  activateMenuLink = (value) => {
    this.setState({activeMenu: value});
  }

  render(){
    // console.log(this.state);
    return (
    <Layout>
      <h1 css={css`
        font-size: 1.5rem;
        font-weight: bold;
        padding: 20px 0;
      `}>FigeysLab IntraWeb Admin Panel</h1>
      <div css={css`
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: npwrap;
        justify-content: space-between;
      `}>
        <div css={css`
            width: 20%;
        `}>
          <Menu activateMenuLink={this.activateMenuLink} />
        </div>
        <div css={css`
          width: 76%;
        `}>
        {
          this.state.activeMenu === 'lab-meeting-schedule'
          &&
          <LabMeetingAdmin />
        }
        {
          this.state.activeMenu === 'ms-status'
          &&
          <MSStatusUpdateAdmin />
        }
        {
          this.state.activeMenu === 'ms-booking'
          &&
          <MSBookingAdmin />
        }
        {
          this.state.activeMenu === 'ms-request-form'
          &&
          <MSRequestFormAdmin />
        } 
        {
          this.state.activeMenu === 'manage-files'
          &&
          <ManageFiles />
        }
        {
          this.state.activeMenu === 'manage-file-categories'
          &&
          <ManageFileCategories />
        }
        {
          this.state.activeMenu === 'manage-file-tags'
          &&
          <ManageFileTags />
        }
        {
          this.state.activeMenu === 'create-new-files'
          &&
          <CreateNewFiles />
        }
        </div>
      </div>
    
    </Layout>
    )
  }
}

export default AdminPage
