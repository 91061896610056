// *** default format for MS Booking Table
// *** used by both:
// *** table.msbooking.js & table.msbooking.admin.js

export const defaultMSBookingTableData = {
  head: ["Time", "Name", "Key Information", "Supporting Information"],
  body: [
    {
      created: "",
      id: "",
      data: [
        // table column one "Time"
        { type: "appliedTime", value: "", label: "Applied Time" },
        { type: "startTime", value: "pending...", label: "Start Time" },
        { type: "status", value: "pending...", label: "Status" },
        // table column two "Name"
        { type: "userName", value: "", label: "Name" },
        // table column three "Key Info"
        { type: "gradientOptions", value: "", label: "Gradient" },
        { type: "sampleCount", value: "", label: "# of Samples" },
        { type: "databaseTypeList", value: "", label: 'Species' },
        { type: "relatedProject", value: "", label: 'Project' },
        { type: "equipmentList", value: "", label: "Equipment" },
        // table column four "Supporting info"
        { type: "sampleReady", value: "", label: "Is the sample ready?" },
        { type: "urgencyLevels", value: "", label: "Is it urgent?" },
        { type: "digestionMethods", value: "", label: "Digestion method" },
        { type: "experimentTypes", value: "", label: "Experiment type" },
        { type: "additionalInfo", value: "", label: "Additonal info" },
        { type: "email", value: "", label: "Contact info" },
      ]
    }
  ]
}