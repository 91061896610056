import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import { withFirebase } from './firebase.index'
// import components
import FileCategoryList from '../elements/Select.Simple';
import FileTagCheckBox from '../elements/CheckBox.Simple';
import FileEditor from '../elements/Modal.EditFile';


class ManageFiles extends React.Component {
  constructor(props){
    super(props);
    this.state={
      // database fetch
      fileTagList: [], //simple array
      filetag: "", //string
      fileCategories: [], //simple array
      filecategory: "", //string
      errMsgFileTag: null,
      errMsgFileCategory: null,

      // storage fetch
      // file list with metadata
      fileList: [],

      // local states
      //for file editor
      showEditor: false,
      showFilterBox: 'none',
      fileForEditing: {}, //{ref: "", name: "", createdAt: "", updatedAt: "", tags: ""}
      selectedTags: [] //simple array
    };

    this.savedFileList = [];
    this.storeRef = props.firebase.storage.ref();
  }

  componentDidMount(){
    // fetch in tag list
    this.props.firebase.filetaglist().get()
    .then((querySnapshot)=>{
      let _arr = [];

      querySnapshot.forEach((doc)=>{
        _arr.push(doc.data().value);
      });

      this.setState({
        fileTagList: _arr,
        selectedTags: [],
      });
    })
    .catch((error)=>{
      console.log(error);
    })
 
    // fetch in categoires
    this.props.firebase.filecategories().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          // console.log(doc.data());
          _arr.push(doc.data().value);
        });

        // update state
        this.setState({fileCategories: _arr});
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  componentDidUpdate(){
    // fetch in tag list
    this.props.firebase.filetaglist().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          _arr.push(doc.data().value);
        });

        // update state only when detecting difference
        let str1 = JSON.stringify(_arr);
        let str2 = JSON.stringify(this.state.fileTagList);
        if(str1 !== str2){
          // update state
          this.setState({fileTagList: _arr});
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    
    // fetch in categories
    this.props.firebase.filecategories().get()
      .then((querySnapshot)=>{
        let _arr = [];

        querySnapshot.forEach((doc)=>{
          _arr.push(doc.data().value);
        });

        // console.log(_arr);
        // update state only when detecting difference
        let str1 = JSON.stringify(_arr);
        let str2 = JSON.stringify(this.state.fileCategories);
        if(str1 !== str2){
          // update state
          this.setState({fileCategories: _arr});
        }
      })
      .catch((error)=>{
        console.log(error);
      })
  }
  
  // load files and metaData for each file
  showMeTheFiles = async () => {
    
    if(this.state.filecategory !== ""){

      let fileRef = this.storeRef.child(this.state.filecategory);
      let _fileList = await getFileList(fileRef);

      // console.log(_fileList);

      let _fullList = await fetchMetaData(this.storeRef, _fileList);

      // console.log(_fullList);
      this.savedFileList = _fullList;
      this.setState({
        fileList: _fullList,
        showFilterBox: 'block',
        errMsgFileCategory: null,
        selectedTags: [],
      })

    }else{
      this.setState({errMsgFileCategory: `Please select a file category`})
    }
  }

  updateCategory = (category) => {
    // console.log(category);
    // console.log(category.value);
    this.setState({
      filecategory: category.value,
      errMsgFileCategory: null,
    });
  }

  updateTag = (tag) => {
    // make a clone
    let _arr = this.state.selectedTags.slice();
    // update selected tag list
    if(_arr.indexOf(tag) > -1){
      _arr.splice(_arr.indexOf(tag), 1);
    }else{
      _arr.push(tag);
    }

    // make a deep clone
    let _files = this.savedFileList.map(file=>{
      return {...file}
    })
    // update file list based on tag selection
    let _fileList = [];
    if(_arr.length > 0){
      _fileList = _files.filter(file => {
        if(file.tags){
          let _fileTags = file.tags.split('_');
          for(let tag of _fileTags){
            if(_arr.indexOf(tag) > -1){
              return file;
            }
          }
        }
      })
    }else{
      _fileList = _files.map(file=>{
        return {...file}
      })
    }
    
    // console.log(_fileList);

    this.setState({
      selectedTags: _arr,
      fileList: _fileList
    });
  }

  editFile = (file) => {
    // console.log(file);
     // update states
     this.setState({
      showEditor: true,
      fileForEditing: file,
    });
  }

  closeFileEditor = () => {
    this.setState({
      showEditor: false,
      fileForEditing: {},
    })
  }

  deleteFile = (file) => {
    // console.log(file);

    this.storeRef.child(file.ref).delete()
    .then(()=>{
      console.log('deletion successful');
      let _fileList = this.state.fileList.filter(_file=>{
        return _file.ref !== file.ref
      });
      // console.log(_fileList);
      this.setState({
        fileList: _fileList
      })
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  saveChanges = (mdString, tags, file) => {
    // console.log(mdString, tags);

    this.storeRef.child(file.ref).putString(mdString)
    .then((snapshot)=>{

      // console.log('success');
      // console.log(snapshot);
      // console.log(snapshot.state);

      // save metadata updates
      // join tags for metadata
      let _tags = tags.slice();
      let metaData = {};
      metaData.customMetadata = {};

      if(_tags.length > 0){
        metaData.customMetadata.tags = _tags.join('_');
      }else{
        metaData.customMetadata.tags = null;
      }

      this.storeRef.child(file.ref).updateMetadata(metaData)
      .then((metadata)=>{
        // console.log(metadata);
        
        let fullPath = metadata.fullPath;
        let customMetadata = {...metadata.customMetadata};
        // update fileList
        let _fileList = this.state.fileList.map(file=>{
          if (file.ref === fullPath){
            file.tags = customMetadata.tags;
          }
          return file;
        })
        // console.log(_fileList);
        this.setState({
          showEditor: false,
          fileForEditing: {},
          fileList: _fileList
        })
      })
      .catch((err)=>{
        console.log(err.message);
      });

    })
    .catch((err)=>{
      // console.log(err); //err is not a string
    });

    
  }
  

  render() {
    // console.log(this.state);
    return (
      <div>

        <div css={css`
          padding: 10px;
          border-radius: 5px;
          background-color: rgba(225, 245, 254, 0.5);
        `}>
          <FileCategoryList 
            label="File Category"
            help="Select under which category the file was saved"
            nameAttr="file-category"
            errMsg={this.state.errMsgFileCategory}
            options={['Select a Category', ...this.state.fileCategories]}
            selectedOption={this.state.filecategory} //only used to reset child component state
            update={this.updateCategory}
          />

          <div 
            css={css`
              margin: 30px 0 0 0;
            `}
            className="button is-link"
            onClick={this.showMeTheFiles}
          >Show me the files</div>
        </div>

        <div css={css`
          margin: 40px 0;
          padding: 10px;
          border-radius: 5px;
          background-color: rgba(225, 245, 254, 0.5);
          display: ${this.state.showFilterBox};
        `}>
          <FileTagCheckBox 
            label="Filter file by tags"
            help=""
            tags={generateTagListFromTagArray(this.state.fileTagList)}
            checkedTags={this.state.selectedTags}
            update={this.updateTag}
          />
        </div>

        <div>
        {
          this.state.fileList.map((file, index)=>{
            return(
              <div key={index} css={css`
                width: 100%;
                padding: 10px 0;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
              `}>

                <div css={css`
                  flex-grow: 1;
                `}>
                  <div css={css`font-size: 1.2rem; font-weight: 600;`}>{`File ${index + 1}: ${file.name}`}</div>
                </div>

                <div css={css`
                  flex-grow: 0;
                `}>
                  {
                    file.tags
                    &&
                    file.tags.split('_').map((tag, i)=>{
                      return(
                        <div key={i} className="tag is-info">{`${tag}`}</div>
                      )
                    })
                  }
                </div>

                <div css={css`margin: 0 10px;`}>
                  <div className="button is-primary"
                    onClick={()=>this.editFile(file)}
                  >Edit</div>
                </div>

                <div css={css`margin: 0 10px;`}>
                  <div className="button is-warning"
                    onClick={()=>this.deleteFile(file)}
                  >Delete</div>
                </div>
              </div>
            )
          })
        }
        </div>

        <FileEditor 
          isActive={this.state.showEditor}
          close={this.closeFileEditor}
          file={this.state.fileForEditing}
          tagList={this.state.fileTagList}
          saveChanges={this.saveChanges}
        />
      </div>
    )
  }
}

export default withFirebase(ManageFiles);

function generateTagListFromTagString(tagstring) {
  let _tags = [];
  if(tagstring !== undefined){
    _tags = tagstring.split("_").map((tag, index)=>{
      let obj={};
      obj.label = tag;
      obj.value = tag;
      obj.name = 'file-tags';
      return obj;
    })
  }
  return _tags;
}

function generateTagListFromTagArray(list) {
  let _tags = [];
  _tags = list.map((tag, index)=>{
    let obj={};
    obj.label = tag;
    obj.value = tag;
    obj.name = 'file-tags';
    return obj;
  })
  return _tags;
}

const fetchMetaData = async (ref, fileList) => {
  let _arr = [];

  for(let i = 0; i< fileList.length; i++){
   await ref.child(fileList[i].ref).getMetadata()
    .then((metadata)=>{
      let obj = {};
      // console.log(metadata);
      obj.createdAt = metadata.timeCreated;
      obj.updatedAt = metadata.updated;
      obj.tags = metadata.customMetadata !== undefined && metadata.customMetadata.tags ? metadata.customMetadata.tags : null; //set to string || null
      obj = {...obj, ...fileList[i]}
      _arr.push(obj);
    })
    .catch((err)=>{
      console.log(err);
    });
  }
  return _arr;
}

const getFileList = async (ref) => {
  let _arr = [];

  await ref.listAll()
  .then((res)=>{
   
    // console.log(res);
    // res{prefixes: [], items: []}

    res.prefixes.forEach((folderRef)=>{
      // console.log('folder ref');
      // console.log(folderRef);
    });

    res.items.forEach((itemRef)=>{
      let obj = {};
      // console.log('item ref');
      // console.log(itemRef);
      // console.log(itemRef.fullPath);
      // console.log(itemRef.name);
      // get path and name for each file
      obj.ref = itemRef.fullPath;
      obj.name = itemRef.name.split('_')[0].split('-').join(' ');
      _arr.push(obj);
    });
    
  })
  .catch((err)=>{
    console.log(err);
  });

  return _arr;
}