import React from 'react';

const dividingArraySegments = (array) => {
  let obj = {};
  let end = array.length;
  obj.col_1 = array.slice(0,3); //2
  obj.col_2 = array.slice(3,4); //1
  obj.col_3 = array.slice(4,9); //5
  obj.col_4 = array.slice(9,end); //last ones
  return obj;
}

class MSBookingTableEdit extends React.Component {
  
  start = (data) => {
    this.props.start(data);
  }

  stop = (data) => {
    this.props.stop(data);
  }

  complete = (data) => {
    this.props.complete(data);
  }

  delete = (data) => {
    this.props.delete(data);
  }

  render(){
    return (
      <table className="table is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            {
              this.props.bookingData.head.map((item, index)=>{
                return (
                  <th key={index}>{item}</th>
                )
              })
            }
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.bookingData.body.map((item, index)=>{
              let data = dividingArraySegments(item.data);
              {/* console.log(data); */}
              return(
                <tr key={index}>
                  <td>
                    <ul>
                    {
                      data.col_1.map((cell, i)=>{
                        {/* console.log(cell); */}
                        return(
                          <li key={cell.type}>{`${cell.label}: ${cell.value}`}</li>
                        )
                      })
                    }
                    </ul>
                    {
                      data.col_1[1].value === 'pending...'
                      &&
                      data.col_1[2].value !== 'complete'
                      &&
                      <button className="button is-primary"
                        onClick={()=>this.start(item)}
                      >Start</button>
                    }
                    {
                      data.col_1[1].value !== 'pending...'
                      &&
                      data.col_1[2].value !== 'complete'
                      &&
                      <button className="button is-warning"
                        onClick={()=>this.stop(item)}
                      >Stop</button>
                    }
                    
                  </td>
                  <td>
                    <ul>
                    {
                      data.col_2.map((cell, i)=>{
                        {/* console.log(cell); */}
                        return(
                          <li key={cell.type}>{`${cell.value}`}</li>
                        )
                      })
                    }
                    </ul>
                  </td>
                  <td>
                    <ul>
                    {
                      data.col_3.map((cell, i)=>{
                        {/* console.log(cell); */}
                        return(
                          <li key={cell.type}>{`${cell.label}: ${cell.value}`}</li>
                        )
                      })
                    }
                    </ul>
                  </td>
                  <td>
                    <ul>
                    {
                      data.col_4.map((cell, i)=>{
                        {/* console.log(cell); */}
                        return(
                          <li key={cell.type}>{`${cell.label}: ${cell.value}`}</li>
                        )
                      })
                    }
                    </ul>
                  </td>
                  <td>
                    <div className="buttons are-small">
                      <button className="button is-link"
                        onClick={()=>this.complete(item)}
                      >Mark as Complete</button>
                      <button className="button is-warning"
                        onClick={()=>this.delete(item)}
                      >Delete record</button>
                    </div>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }
}

export default MSBookingTableEdit;