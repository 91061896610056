import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import { withFirebase } from './firebase.context'
// import components
import MSBookingTableEdit from '../elements/Table.MSBooking.Edit';
// import default data
import { defaultMSBookingTableData } from '../constants/msBookingTableData';

const getStartTime = () => {
  let dateTime = "";
  let today = new Date();
  let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  dateTime = date + ' ' + time;
  return dateTime;
}

class MSBookingSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingData: { ...defaultMSBookingTableData },
      showCompleted: false
    }
  }

  componentDidMount() {
    this.fetchBookings();
  }

  fetchBookings = () => {
    let msBookingData = {};
    let _arr = [];

    this.props.firebase.msrequestdata()
      .orderBy('created')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // deep clone booking data
          let obj = {};
          obj = JSON.parse(JSON.stringify(doc.data()));
          obj.id = doc.id; // id will be used to update individual record in database
          let isComplete = obj.data[2].value === "complete";
          // add individual record to super array
          if (!(isComplete && !this.state.showCompleted)) {
            _arr.push(obj);
          }
        });

        // generate table component header
        msBookingData.head = defaultMSBookingTableData.head;
        // generate table component body
        msBookingData.body = _arr;

        // order from most recent
        msBookingData.body.reverse();
        this.setState({ bookingData: msBookingData })
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  handleCheckShowCompleted = (event) => {
    const target = event.target;

    this.setState({
      showCompleted: target.checked
    }, this.fetchBookings);
  }

  start = (data) => {
    // console.log(`start this specific session please :)`);
    // console.log(data); // {created: {}, data: [], id: ""}
    this.updateDataArrayOfRecordInFireStore(data, 'start', 'startTime');

  }

  stop = (data) => {
    // console.log(`stop a running session`);
    // console.log(data);
    this.updateDataArrayOfRecordInFireStore(data, 'stop', 'startTime');
  }

  complete = (data) => {
    // console.log(`mark as complete`);
    // console.log(data);
    this.updateDataArrayOfRecordInFireStore(data, '', 'status');
  }

  delete = (data) => {
    // console.log(`delete this record`);
    // console.log(data);

    let msBookingRecords = JSON.parse(JSON.stringify(this.state.bookingData));
    // console.log(msBookingRecords);

    // *** deep clone the returned record
    let msBookingRecord = JSON.parse(JSON.stringify(data));

    this.props.firebase.msrequestdata().doc(msBookingRecord.id)
      .delete()
      .then(() => {
        // console.log("Document successfully deleted!");
        msBookingRecords.body.forEach((record, index) => {
          if (record.id === msBookingRecord.id) {
            // *** remove the records from the array
            msBookingRecords.body.splice(index, 1);
          }
        });

        this.setState({
          bookingData: msBookingRecords
        })
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  // *** update only the data array part of the record in firestore
  // *** type determines the updatd values
  // *** type: 'start' -> the current time
  // *** type: 'stop' -> 'pending...'
  // *** field determines which item to update
  // *** field: 'startTime' || 'status'
  updateDataArrayOfRecordInFireStore = (data, type, field) => {
    let msBookingRecords = JSON.parse(JSON.stringify(this.state.bookingData));
    // console.log(msBookingRecords);

    // *** deep clone the returned record
    let msBookingRecord = JSON.parse(JSON.stringify(data));

    // case I
    if (field === 'startTime') {
      // *** find 'startTime' property inside property {data:[]}
      // *** update its value to the current time
      msBookingRecord.data.forEach((item, index) => {
        if (item.type === 'startTime') {
          if (type === 'start') {
            msBookingRecord.data[index]['value'] = getStartTime();
          } else if (type === 'stop') {
            msBookingRecord.data[index]['value'] = 'pending...';
          }
        }
      });
      // console.log(msBookingRecord);
    }

    // cast II
    if (field === 'status') {
      // *** find 'status' property inside property {data:[]}
      // *** update its value to 'complete'
      msBookingRecord.data.forEach((item, index) => {
        if (item.type === 'status') {
          msBookingRecord.data[index]['value'] = 'complete';
        }
      });
      // console.log(msBookingRecord);
    }

    // *** deep clone only the data array part
    let _data = JSON.parse(JSON.stringify(msBookingRecord.data));

    // *** update the data array of the record in firestore
    this.props.firebase.msrequestdata().doc(msBookingRecord.id)
      .update({
        data: _data
      })
      .then(() => {
        // console.log(`successfully updated ${_id}`);
        msBookingRecords.body.forEach((record, index) => {
          if (record.id === msBookingRecord.id) {
            // *** update the records
            msBookingRecords.body[index].data = _data;
          }
        });

        this.setState({
          bookingData: msBookingRecords
        })
      });
  }

  render() {
    // console.log(this.state);
    return (
      <div css={css`
        width: 100%;
        padding: 20px 0;
        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 20px 0;
        }
      `}>
        <h2>MS Booking Schedule</h2>
        <label>
          <input
            name="showCompleted"
            type="checkbox"
            checked={this.state.showCompleted}
            onChange={this.handleCheckShowCompleted} />
          Show Completed Bookings
        </label>
        <MSBookingTableEdit
          bookingData={this.state.bookingData}
          start={this.start}
          stop={this.stop}
          complete={this.complete}
          delete={this.delete} />
      </div>
    )
  }
}

export default withFirebase(MSBookingSchedule);