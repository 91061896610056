import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import data
import {adminMenuData} from '../constants/menuData';

class AdminMenu extends React.Component {
  constructor(props){
    super(props);
    this.state={
      activeList: adminMenuData[0].subList[0].value,
    }
  }

  _onClick = (e) => {
    // console.log(e.target.dataset.value);
    this.setState({
      activeList: e.target.dataset.value
    })

    this.props.activateMenuLink(e.target.dataset.value);
  }

  render(){
    return(
      <aside className="menu">
      {
        adminMenuData.map((item, index)=>{
          return (
            <div key={index} css={css`margin: 10px 0;`}>
              <p className="menu-label">
                {item.label}
              </p>
              <ul className="menu-list">
                {
                  item.subList.map((list, num)=>{
                    return(
                      <li key={num}>
                        {
                          list.value === this.state.activeList
                          &&
                          <a className="is-active" 
                            data-value={list.value}
                            onClick={this._onClick}>{list.label}</a>
                        }
                        {
                          list.value !== this.state.activeList
                          &&
                          <a data-value={list.value}
                            onClick={this._onClick}>{list.label}</a>
                        }
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          )
        })
      }
      </aside>
    )
  }
}

export default AdminMenu;