import React from 'react';
// import component
import MDEditor from '../elements/MDEditor';

const FileEditor = (props) => {
  return (
    <div>
      <MDEditor 
        mdString={props.mdString}
        getMdString={props.getMdString}
      />
    </div>
  )
}

export default FileEditor;