import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from './firebase.context';
// import component
import MSStatusTable from '../elements/Table.MSStatus.Edit';
import ModalForMSStatusEditing from '../elements/Modal.MSStatus';

const getSingleStatusDataAsObject = (doc) => {
  let _item = {};
  // equipment: "Equipment",
  // status: "Status",
  // firestore docRef
  _item.equipment = doc.data().equipment || '';
  _item.status = doc.data().status || '';
  _item.id = doc.id || '';
  return _item;
}

class MSStatusUpdateAdmin extends React.Component {
  constructor(props){
    super(props);
    this.state={
      msStatusData: {...defaultMSStatusData},
      selectedItem: {},
      showEditableModal: 'not-active'
    }
  }

   // *** initial data fetching && loading ***
   componentDidMount(){
    // console.log('admin ms status component did mount');

    let msStatusData = {};
    let _arr = [];

    // format data for table component header
    msStatusData.head = [
      "Equipment", "Status"
    ];

    this.props.firebase.msstatus()
      .orderBy("equipment").limit(20)
      .get()
      .then((querySnapshot)=>{
        querySnapshot.forEach((doc)=>{
          let _item = getSingleStatusDataAsObject(doc);
          _arr.push(_item);
        });
        // generate table component body
        msStatusData.body = _arr;
        this.setState({msStatusData})
      })
      .catch((error)=>{
        // console.log(error);
      })
  }

  // *** data updating && loading based on changes ***
  componentDidUpdate(prevProps, prevState){
    // console.log('admin ms status component did update');

    let msStatusData = {};
    let _arr = [];

    // format data for table component header
    msStatusData.head = [
      "Equipment", "Status"
    ];

    this.props.firebase.msstatus()
      .orderBy("equipment").limit(20)
      .get()
      .then((querySnapshot)=>{
        querySnapshot.forEach((doc)=>{
          let _item = getSingleStatusDataAsObject(doc);
          _arr.push(_item);
        });
        // generate table component body
        msStatusData.body = _arr;

        // compare prev msStatusData with currently fetched msStatusData
        let str1 = JSON.stringify(prevState.msStatusData);
        let str2 = JSON.stringify(msStatusData);

        if(str1 !== str2){
          // console.log('updates detected');
          this.setState({msStatusData});
        }
      })
      .catch((error)=>{
        // console.log(error);
      })
  }
  
  _onCloseModal = () => {
    this.setState({
      selectedItem: {},
      showEditableModal: 'not-active'
    })
  }

  /**
   * this function will have to distinguish update existing & add new
   * into firestore database by checking if there is an id property
   * 
   * 1. update: returned data have id property
   * 2. add: returned data doesn't have id property
   */

  _onSaveChanges = (item) => {
    // console.log(item);

    // *** Update an existing schedule at /mstatus***
    if(item.id){
      // console.log(item.id);
      this.props.firebase.msstatus().doc(item.id)
        .update({...item})
        .then(()=>{
          // console.log(`successfully updated ${item.id}`);
          this.setState({
            showEditableModal: 'not-active',
            selectedItem: {}
          })
        })
    }
    // *** Add a new ms status at /mstatus***
    else{
      this.props.firebase.msstatus()
      .add({...item})
      .then((doc)=>{
        // console.log("new document added with ID: ", doc.id);
        this.setState({
          showEditableModal: 'not-active',
          selectedItem: {}
        })
      })
      .catch((error)=>{
        // console.error("Error adding document: ", error);
        this.setState({
          showEditableModal: 'not-active',
          selectedItem: {}
        })
      });
    }
  }

  _onEnableEditing = (item) => {
    // console.log(item);
    this.setState({
      selectedItem: item,
      showEditableModal: 'is-active'
    })
  }

  _onDelete = (item) => {
    // console.log('attempt to delete item:');
    // console.log(item);
    this.props.firebase.msstatus().doc(item.id)
    .delete()
    .then(()=>{
      // console.log("Document successfully deleted!");
      this.setState({
        selectedItem: {}
      })
    })
    .catch((error)=>{
      // console.error("Error removing document: ", error);
    });
  }

  _onAddNewItem = () => {
    this.setState({
      selectedItem: {},
      showEditableModal: 'is-active'
    })
  }

  render(){
    return (
      <div css={css`
        width: 100%;
        padding: 20px 0;
        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 20px 0;
        }
      `}>
        <h2>MS Equipment Status</h2>
        <MSStatusTable 
          msStatusData={this.state.msStatusData}
          onEdit={this._onEnableEditing}
          onDelete={this._onDelete} />

        
        <button className="button is-primary is-fullwidth"
          onClick={this._onAddNewItem}
        >Add A New Entry</button>

        <ModalForMSStatusEditing 
          selectedItem={this.state.selectedItem}
          editCancel={this._onCloseModal}
          editSubmit={this._onSaveChanges}
          active={this.state.showEditableModal} 
        />
      </div>
    )
  }
}

export default withFirebase(MSStatusUpdateAdmin);

const defaultMSStatusData = {
  head: ["Equipment", "Status"],
  body: [
    {equipment: 'Oribtrap Elite', status: 'Running'},
    {equipment: 'QE', status: 'Running'},
    {equipment: 'QE-HFX', status: 'Running'},
    {equipment: 'OQtrap5500', status: 'Down'},
  ]
}