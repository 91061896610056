import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import { withFirebase } from '../components/firebase.index'
// import components
import FileEditor from './MDEditor';
import CheckBoxEle from './CheckBox.Simple';

/**
 * @param {object} props
 * @param {boolean} props.isActive
 * @param {object} props.file - all file specific info
 * @param {function} props.close - close modal
 * 
 * @param {object} state
 * @param {array} state.tagList
 * @param {array} state.checkedTags
 * @param {string} state.mdString
 * 
 */

class ModalForEditingFile extends React.Component {
  constructor(props){
    super(props);
    this.state={
      tagList: [],
      checkedTags: [],
      mdString: '',
    }
    this.storeRef = props.firebase.storage.ref();
  }

  componentDidMount(){
    // file specific tag list
    // @input {string} - 'a_b'
    // @output {array} - ['a', 'b']
    let _checkedTags = this.props.file.tags && this.props.file.tags !== '_' ? this.props.file.tags.split('_').map(tag=>tag) : [];
    // default tag list
    // @input {array} - ['A', 'B', 'C', 'D']
    // @output {array} - [{label: '', value: '', name: ''}, {}]
    let _tagList = this.props.tagList ? generateTagListFromTagArray(this.props.tagList): [];
    // set init local state
    this.setState({
      checkedTags: _checkedTags,
      tagList: _tagList
    });
  }

  componentDidUpdate(prevProps){
    // console.log(prevProps);
    // console.log(this.props);

    // only when the modal is active && file name changed
    if(this.props.isActive && prevProps.file.name !== this.props.file.name){
      // console.log('file changed');
      let _checkedTags = this.props.file.tags && this.props.file.tags !== '_' ? this.props.file.tags.split('_').map(tag=>tag) : [];
      let _tagList = this.props.tagList ? generateTagListFromTagArray(this.props.tagList): [];
      // update tags
      this.setState({
        tagList: _tagList,
        checkedTags: _checkedTags
      })

      // fetch file content
      this.storeRef.child(this.props.file.ref).getDownloadURL()
      .then((url)=>{

        // HttpRequest
        // let xhr = new XMLHttpRequest();
        // xhr.responseType = 'text';
        // xhr.onload = (e) => {
        //   let res = xhr.response;
        //   // console.log(res);
        //   // update state
        //   this.setState({
        //     showEditor: true,
        //     fileForEditing: file,
        //     mdString: res
        //   });
        // }
        // xhr.open('GET', url);
        // xhr.send();

        // fetch API
        fetch(url, {
          method: 'get',
        })
        .then(response => {
          // console.log(response);
          if(response.ok){
            return response.text();
          }
        })
        .then((res)=>{
          // console.log(res);
          // update states on file contents
          this.setState({mdString: res});
        })
        .catch(function(err) {
          console.log(err);
        });

      })
      .catch(err=>{
        console.log(err);
      })
    }
  }

  updateTag = (tag) => {
    let _arr = this.state.checkedTags.slice();

    if(_arr.indexOf(tag) > -1){
      _arr.splice(_arr.indexOf(tag), 1);
    }else{
      _arr.push(tag);
    }

    // update local states on tags
    this.setState({checkedTags: _arr});
  }

  closeModal = () => {
    // reset local states
    this.setState({
      tagList: [],
      checkedTags: [],
      mdString: '',
    })
    // report to parent
    this.props.close();
  }

  // report to parent
  saveChanges = (mdString) => {
    let _checkedTags = this.state.checkedTags;
    this.props.saveChanges(mdString, _checkedTags, this.props.file);
  }

  render(){
    // console.log(this.state);
    return (
      <div className={this.props.isActive ? `modal is-active`: "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card"
          css={css`
            width: 90%;
          `}
        >
          <header className="modal-card-head">
            <p className="modal-card-title">{`Edit File ${this.props.file.name}` || `Edit File`}</p>
            <button className="delete" 
              aria-label="close"
              onClick={this.closeModal}
              ></button>
          </header>
          <section className="modal-card-body">
            
            <CheckBoxEle 
              label="File Tags (optional)"
              help="File tags can become handy when search files within the same file category"
              tags={this.state.tagList}
              checkedTags={this.state.checkedTags}
              update={this.updateTag}
            />

            <div>
              <p>Created: {this.props.file.createdAt}</p>
              <p>Last updated: {this.props.file.updatedAt}</p>
            </div>

            <FileEditor 
              mdString={this.state.mdString}
              getMdString={this.saveChanges}
            />
          </section>
        </div>
      </div>
    )
  }
}

export default withFirebase(ModalForEditingFile);

ModalForEditingFile.defaultProps = {
  isActive: false,
  file: {},
  tagList: [],
}

function generateTagListFromTagString(tagstring) {
  let _tags = [];
  if(tagstring !== undefined){
    _tags = tagstring.split("_").map((tag, index)=>{
      let obj={};
      obj.label = tag;
      obj.value = tag;
      obj.name = 'file-tags';
      return obj;
    })
  }
  return _tags;
}

function generateTagListFromTagArray(list) {
  let _tags = [];
  _tags = list.map((tag, index)=>{
    let obj={};
    obj.label = tag;
    obj.value = tag;
    obj.name = 'file-tags';
    return obj;
  })
  return _tags;
}